export const BACKEND_HOST = process.env.REACT_APP_BACKEND_HOST_NAME || 'https://api.vforce.ai';
console.log('-- backend host', BACKEND_HOST)
export const WS_HOST_NAME = process.env.REACT_APP_WS_HOST_NAME || 'wss://api.vforce.ai';
console.log('-- ws host', WS_HOST_NAME)
export const GOOGLE_AUTH_CLIENT_ID = process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID || '1026773688899-bsn3m8j75gotbv94glqh3953160pu19u.apps.googleusercontent.com';
console.log('-- google auth client id', GOOGLE_AUTH_CLIENT_ID)
export const APP_NAME = "choso"
export const HOST_NAME = process.env.REACT_APP_HOST_NAME || 'https://choso.live'
console.log('-- host name', HOST_NAME)
export const ws_classroom_channel = () => `${WS_HOST_NAME}/ws/classroom`
export const backend_path_room_number = code => `${BACKEND_HOST}/api/${APP_NAME}/rooms/${code}/numbers`
export const backend_path_room = code => `${BACKEND_HOST}/api/${APP_NAME}/rooms/${code}`
export const ably_path_room_channel = code => `room_channel_${code}`
export const ably_path_auth = `${BACKEND_HOST}/api/${APP_NAME}/ably/auth`
export const backend_path_owner_login = `${BACKEND_HOST}/api/${APP_NAME}/auth/owner/login`
export const backend_path_business = `${BACKEND_HOST}/api/${APP_NAME}/businesses/`;
export const backend_path_employees = `${BACKEND_HOST}/api/${APP_NAME}/employees`;
export const backend_path_employees_invite = `${BACKEND_HOST}/api/${APP_NAME}/employees/invite`;
