import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from '../../axiosConfig';
import { backend_path_business } from '../../constants';
import { AuthProvider } from '../../AuthProvider';
import NavigationMenu from '../../NavigationMenu';
const BusinessList = () => {
    const [businesses, setBusinesses] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchBusinesses();
    }, []);

    const fetchBusinesses = async () => {
        try {
            const response = await axios.get(backend_path_business);
            setBusinesses(response.data);
            setLoading(false);
        } catch (err) {
            setError('Failed to fetch businesses');
            setLoading(false);
        }
    };

    const handleDelete = async (id) => {
        if (window.confirm('Bạn có chắn chắn muốn xóa cơ sở này không?')) {
            try {
                await axios.delete(`${backend_path_business}${id}/`);
                setBusinesses(businesses.filter(business => business.id !== id));
            } catch (err) {
                setError('Failed to delete business');
            }
        }
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div className="alert alert-danger">{error}</div>;

    return (
        <div className="container mt-4">
            <div className="d-flex justify-content-between align-items-center mb-4">
                <h2>Các cơ sở của bạn</h2>
                <Link to="/business/create" className="btn btn-success">
                    Thêm cơ sở mới <i className="bi bi-plus"></i>
                </Link>
            </div>
            <div className="row">
                {businesses.map(business => (
                    <div key={business.id} className="col-md-4 mb-4">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">{business.name}</h5>
                                <p className="card-text"><i className="bi bi-phone"></i>&nbsp;{business.phone_number}</p>
                                <p className="card-text"><i className="bi bi-geo-alt"></i>&nbsp;{business.address}</p>
                                <div className="mt-3">
                                    <Link to={`/business/${business.id}`} className="btn btn-info me-2">
                                        <i className="bi bi-eye"></i>&nbsp;
                                        Xem
                                    </Link>
                                    <Link to={`/business/edit/${business.id}`} className="btn btn-warning me-2">
                                        <i className="bi bi-pencil"></i>&nbsp;
                                        Thay đổi
                                    </Link>
                                    <button
                                        onClick={() => handleDelete(business.id)}
                                        className="btn btn-danger"
                                    >
                                        <i className="bi bi-trash"></i>&nbsp;
                                        Xóa
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default BusinessList; 