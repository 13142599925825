import React, { useEffect, useState } from 'react';
import { useAuth } from './AuthProvider';
import { useDispatch, useSelector } from 'react-redux';
import { setUser, setToken } from './store/userSlice';
import { selectUser } from './store/userSlice';
import { BACKEND_HOST, GOOGLE_AUTH_CLIENT_ID, backend_path_owner_login } from './constants';
import './Dashboard.css';

// Dashboard Component
const Dashboard = () => {
    const dispatch = useDispatch();
    const { isAuthenticated, login, logout } = useAuth();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const userProfile = useSelector(selectUser);

    useEffect(() => {
        if (!isAuthenticated) {
            const loadGoogleScript = () => {
                const script = document.createElement('script');
                script.src = 'https://accounts.google.com/gsi/client';
                script.async = true;
                script.defer = true;
                document.body.appendChild(script);

                script.onload = () => {
                    window.google.accounts.id.initialize({
                        client_id: GOOGLE_AUTH_CLIENT_ID,
                        callback: handleCredentialResponse
                    });
                    window.google.accounts.id.renderButton(
                        document.getElementById('googleSignInButton'),
                        { theme: 'outline', size: 'large' }
                    );
                };
            };

            loadGoogleScript();
        }
    }, [isAuthenticated]);

    const handleOwnerLogin = async (e) => {
        e.preventDefault();
        setError('');

        try {
            const res = await fetch(backend_path_owner_login, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email, password })
            });

            if (!res.ok) {
                throw new Error('Invalid credentials');
            }

            const data = await res.json();
            const token = data['access'];

            if (!token) {
                throw new Error('Invalid token received from server');
            }

            // Store refresh token
            localStorage.setItem('refreshToken', data['refresh']);

            // Update Redux store (which also updates localStorage)
            dispatch(setToken(token));
            dispatch(setUser({
                email: data.user.email,
                id: data.user.id,
                is_owner: true,
                owner_id: data.user.owner_id
            }));

            login(token);
        } catch (error) {
            setError(error.message);
            console.error('Login error:', error.message);
        }
    };

    const handleCredentialResponse = async (response) => {
        try {
            const res = await fetch(`${BACKEND_HOST}/api/auth/google/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ credential: response.credential })
            });

            if (!res.ok) {
                throw new Error('Failed to authenticate with the server');
            }

            const data = await res.json();
            const token = data['access'];

            if (!token) {
                throw new Error('Invalid token received from server');
            }

            // Store refresh token
            localStorage.setItem('refreshToken', data['refresh']);

            // Update Redux store (which also updates localStorage)
            dispatch(setToken(token));
            dispatch(setUser({
                ...data.user,
                is_google_user: true
            }));

            login(token);
        } catch (error) {
            console.error('Login error:', error.message);
        }
    };

    return (
        <div className="dashboard-container">
            <div className="row h-100 g-0">
                {/* Left Pane (Split Login) */}
                <div className="col-md-6 d-flex flex-column login-pane">
                    {isAuthenticated ? (
                        <div className="h-100 d-flex justify-content-center align-items-center">
                            <div className="text-center">
                                <h2 className="mb-4">Chào mừng {userProfile && userProfile.is_owner ? 'chủ doanh nghiệp' : 'người dùng'} {userProfile ? userProfile.email : '<invalid-user-profile>'}</h2>
                                <button onClick={logout} className="btn btn-primary btn-lg">Log Out</button>
                            </div>
                        </div>
                    ) : (
                        <>
                            {/* Owner Login Form (Upper Half) */}
                            <div className="flex-grow-1 d-flex justify-content-center align-items-center border-bottom">
                                <div className="w-75">
                                    <h3 className="text-center mb-4">Business Owner Login</h3>
                                    <form onSubmit={handleOwnerLogin}>
                                        {error && (
                                            <div className="alert alert-danger" role="alert">
                                                {error}
                                            </div>
                                        )}
                                        <div className="mb-3">
                                            <label htmlFor="email" className="form-label">Email</label>
                                            <input
                                                type="email"
                                                className="form-control"
                                                id="email"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="password" className="form-label">Password</label>
                                            <input
                                                type="password"
                                                className="form-control"
                                                id="password"
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <button type="submit" className="btn btn-primary w-100">
                                            Login as Owner
                                        </button>
                                    </form>
                                </div>
                            </div>

                            {/* Google Login (Lower Half) */}
                            <div className="flex-grow-1 d-flex justify-content-center align-items-center">
                                <div className="text-center">
                                    <h3 className="mb-4">User Login</h3>
                                    <div id="googleSignInButton"></div>
                                </div>
                            </div>
                        </>
                    )}
                </div>

                {/* Right Pane (App Info) */}
                <div className="col-md-6 info-pane d-flex flex-column justify-content-center align-items-center p-5">
                    <img
                        src="https://via.placeholder.com/400"
                        alt="App Logo"
                        className="img-fluid mb-5"
                        style={{ maxWidth: '400px' }}
                    />
                    <div className="company-description">
                        <h2 className="text-primary mb-4">Choso.live – Đặt lịch nhanh chóng, không còn chờ đợi</h2>
                        <p className="lead mb-4">
                            Choso.live là nền tảng SaaS dành cho các doanh nghiệp nhỏ và vừa, 
                            đặc biệt là các cơ sở y tế, nhằm giúp:
                        </p>

                        <div className="features-list">
                            <div className="feature-item">
                                <i className="bi bi-building me-2"></i>
                                Quản lý nhiều cơ sở, nhân sự một cách hiệu quả
                            </div>
                            
                            <div className="feature-item">
                                <i className="bi bi-clock-history me-2"></i>
                                Cải thiện trải nghiệm khách hàng bằng cách thông báo khi đến lượt
                            </div>
                            
                            <div className="feature-item">
                                <i className="bi bi-calendar-check me-2"></i>
                                Cung cấp dịch vụ đặt lịch hẹn trực tuyến tiện lợi
                            </div>
                        </div>

                        <div className="conclusion mt-4">
                            <p className="text-muted">
                                Với Choso.live, các cơ sở y tế có thể tối ưu hóa quy trình làm việc, 
                                giảm thời gian chờ đợi và nâng cao sự hài lòng của khách hàng.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
