import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from '../../axiosConfig';
import { backend_path_business } from '../../constants';

const BusinessDetail = () => {
    const { id } = useParams();
    const [business, setBusiness] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchBusiness();
    }, [id]);

    const fetchBusiness = async () => {
        try {
            const response = await axios.get(`${backend_path_business}${id}/`);
            setBusiness(response.data);
            setLoading(false);
        } catch (err) {
            setError('Failed to fetch business details');
            setLoading(false);
        }
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div className="alert alert-danger">{error}</div>;
    if (!business) return <div>Business not found</div>;

    return (
        <div className="container mt-4">
            <div className="card">
                <div className="card-body">
                    <h2 className="card-title">{business.name}</h2>
                    <div className="row mt-4">
                        <div className="col-md-6">
                            <p><strong>Address:</strong> {business.address}</p>
                            <p><strong>Phone:</strong> {business.phone_number}</p>
                            <p><strong>Email:</strong> {business.email}</p>
                        </div>
                        <div className="col-md-6">
                            <p><strong>Description:</strong></p>
                            <p>{business.description}</p>
                        </div>
                    </div>
                    <div className="mt-4">
                        <Link to={`/business/edit/${id}`} className="btn btn-warning me-2">
                            Edit Business
                        </Link>
                        <Link to="/businesses" className="btn btn-secondary">
                            Back to List
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BusinessDetail; 