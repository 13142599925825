import React, { useState, useEffect } from 'react';
import axios from '../../axiosConfig';
import { useAuth } from '../../AuthProvider';
import './EmployeeManagement.css';
import { backend_path_business, backend_path_employees, backend_path_employees_invite } from '../../constants';

const EmployeeManagement = () => {
    const [email, setEmail] = useState('');
    const [employees, setEmployees] = useState([]);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const { isAuthenticated } = useAuth();
    const [selectedBusinesses, setSelectedBusinesses] = useState([]);
    const [businesses, setBusinesses] = useState([]);

    useEffect(() => {
        if (isAuthenticated) {
            fetchBusinesses();
            fetchEmployees();
        }
    }, [isAuthenticated]);

    const fetchEmployees = async () => {
        try {
            const response = await axios.get(backend_path_employees);
            setEmployees(response.data);
        } catch (err) {
            setError('Failed to fetch employees');
        }
    };

    const fetchBusinesses = async () => {
        try {
            const response = await axios.get(backend_path_business);
            setBusinesses(response.data);
        } catch (err) {
            setError('Failed to fetch businesses');
        }
    };

    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    const handleInvite = async (e) => {
        e.preventDefault();
        setError('');
        setSuccess('');

        if (!validateEmail(email)) {
            setError('Please enter a valid email address');
            return;
        }

        if (selectedBusinesses.length === 0) {
            setError('Please select at least one business');
            return;
        }

        try {
            const response = await axios.post('/api/employees/invite/', {
                email,
                business_ids: selectedBusinesses
            });
            setSuccess('Invitation sent successfully');
            setEmail('');
            fetchEmployees();
        } catch (err) {
            setError(err.response?.data?.error || 'Failed to send invitation');
        }
    };

    const handleBusinessSelect = (businessId) => {
        setSelectedBusinesses(prevSelected => {
            if (prevSelected.includes(businessId)) {
                return prevSelected.filter(id => id !== businessId);
            } else {
                return [...prevSelected, businessId];
            }
        });
    };

    return (
        <div className="container mt-5">
            <div className="row">
                <div className="col-md-8 offset-md-2">
                    <h2 className="mb-4">Employee Management</h2>

                    {/* Invite Form */}
                    <div className="card mb-4">
                        <div className="card-body">
                            <h5 className="card-title">Invite New Employee</h5>
                            <form onSubmit={handleInvite}>
                                <div className="mb-3">
                                    <label className="form-label">Email address</label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        placeholder="Enter employee email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Chọn phòng khám nhân viên sẽ làm việc (có thể chọn nhiều cơ sở): </label>
                                    <div className="business-checkboxes">
                                        {businesses.map(business => (
                                            <div key={business.id} className="form-check">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id={`business-${business.id}`}
                                                    checked={selectedBusinesses.includes(business.id)}
                                                    onChange={() => handleBusinessSelect(business.id)}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor={`business-${business.id}`}
                                                >
                                                    {business.name}
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                    {businesses.length === 0 && (
                                        <div className="text-muted">
                                            No businesses available
                                        </div>
                                    )}
                                </div>
                                <button
                                    className="btn btn-primary"
                                    type="submit"
                                    disabled={selectedBusinesses.length === 0}
                                >
                                    Gửi lời mời tham gia
                                </button>
                                {error && (
                                    <div className="alert alert-danger mt-3">{error}</div>
                                )}
                                {success && (
                                    <div className="alert alert-success mt-3">{success}</div>
                                )}
                            </form>
                        </div>
                    </div>

                    {/* Employees List */}
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">Current Employees</h5>
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>Status</th>
                                            <th>Phone</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {employees.map((employee) => (
                                            <tr key={employee.id}>
                                                <td>{employee.name}</td>
                                                <td>{employee.email}</td>
                                                <td>
                                                    <span className={`badge ${employee.is_active
                                                            ? 'bg-success'
                                                            : 'bg-warning'
                                                        }`}>
                                                        {employee.is_active
                                                            ? 'Active'
                                                            : 'Pending'
                                                        }
                                                    </span>
                                                </td>
                                                <td>{employee.phone_number || '-'}</td>
                                            </tr>
                                        ))}
                                        {employees.length === 0 && (
                                            <tr>
                                                <td colSpan="4" className="text-center">
                                                    No employees found
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EmployeeManagement; 