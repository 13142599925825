import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from './AuthProvider';
import { useSelector } from 'react-redux';
import { selectUser } from './store/userSlice';
import './NavigationMenu.css';

const NavigationMenu = () => {
    const location = useLocation();
    const [isOpen, setIsOpen] = useState(false);
    const [showUserMenu, setShowUserMenu] = useState(false);
    const { logout } = useAuth();
    const user = useSelector(selectUser);

    const toggleMenu = () => setIsOpen(!isOpen);
    const toggleUserMenu = () => setShowUserMenu(!showUserMenu);

    return (
        <nav className='navbar navbar-expand-lg navbar-dark bg-primary'>
            <div className='container'>
                <Link to="/" className="navbar-brand">CHOSO</Link>
                <button className="navbar-toggler" type="button" onClick={toggleMenu}>
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className={`collapse navbar-collapse ${isOpen ? 'show' : ''}`}>
                    <ul className="navbar-nav me-auto">
                        <li className={`nav-item ${location.pathname === '/' ? 'active' : ''}`}>
                            <Link to="/" className="nav-link" onClick={() => setIsOpen(false)}>Trang chủ</Link>
                        </li>
                        <li className={`nav-item ${location.pathname === '/rooms' ? 'active' : ''}`}>
                            <Link to="/rooms" className="nav-link" onClick={() => setIsOpen(false)}>Phòng</Link>
                        </li>
                        {user && user.is_owner && (
                            <>
                                <li className={`nav-item ${location.pathname === '/businesses' ? 'active' : ''}`}>
                                    <Link to="/businesses" className="nav-link" onClick={() => setIsOpen(false)}>Doanh nghiệp</Link>
                                </li>
                                <li className={`nav-item ${location.pathname === '/calendar' ? 'active' : ''}`}>
                                    <Link to="/calendar" className="nav-link" onClick={() => setIsOpen(false)}>Lịch</Link>
                                </li>
                                <li className={`nav-item ${location.pathname === '/employees' ? 'active' : ''}`}>
                                    <Link to="/employees" className="nav-link" onClick={() => setIsOpen(false)}>Nhân sự</Link>
                                </li>
                            </>
                        )}
                    </ul>

                    {user && (
                        <div className="user-menu-container">
                            <div className="d-flex align-items-center">
                                <span
                                    className={`text-light me-3 ${user.is_owner ? 'owner-email' : ''}`}
                                    title={user.is_owner ? "Business Owner" : ""}
                                >
                                    {user.email}
                                </span>
                                <div className="user-icon-wrapper" onClick={toggleUserMenu}>
                                    <div className="user-icon">
                                        {user.email[0].toUpperCase()}
                                    </div>
                                    {showUserMenu && (
                                        <div className="user-dropdown">
                                            <div className="user-dropdown-item">
                                                <Link to="/profile" className="dropdown-link">
                                                    Profile
                                                </Link>
                                            </div>
                                            <div className="user-dropdown-item">
                                                <button onClick={logout} className="dropdown-button">
                                                    Logout
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </nav>
    );
};

export default NavigationMenu;
