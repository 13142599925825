import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { QRCodeSVG } from 'qrcode.react';
import NavigationMenu from './NavigationMenu';
import { AuthProvider } from './AuthProvider';
import {
    HOST_NAME,
    ws_classroom_channel,
    backend_path_room,
    backend_path_room_number,
    ably_path_room_channel,
    ably_path_auth
} from './constants';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import axios from './axiosConfig';
import Ably from "ably"; // Using Ably with promises
import { useTranslation } from 'react-i18next';



const OwnerRoom = () => {
    const { code } = useParams();
    const navigate = useNavigate();
    const [classroom, setClassroom] = useState(null);
    const [qrCodeUrl, setQrCodeUrl] = useState('');
    const [currentNumber, setCurrentNumber] = useState(0);
    const [inputNumber, setInputNumber] = useState(0);
    const printFrameRef = useRef(null);
    const ably = new Ably.Realtime({ authUrl: ably_path_auth });
    const ably_room_channel = ably.channels.get(ably_path_room_channel(code));
    const { t } = useTranslation()

    useEffect(() => {
        const fetchClassroomData = async () => {
            try {
                const response = await axios.get(backend_path_room(code));
                console.log('-- classroom data', response.data)
                setClassroom(response.data);
                setInputNumber(response.data.last_number);
                setCurrentNumber(response.data.last_number);
                const url = `${HOST_NAME}/rooms/${code}`;
                setQrCodeUrl(url);
            } catch (error) {
                console.error('-- Failed to fetch classroom data:', error);
                // navigate('/join_room');
            }
        };

        if (code) {
            console.log('-- fetching classroom data', code)
            fetchClassroomData();
        } else {
            // console.log('-- no code, navigating to join room')
            // navigate('/join_room');
        }
    }, [code, navigate]);

    const updateAndSendNumber = async (value) => {
        setCurrentNumber(value);
        setInputNumber(value);
        axios.post(backend_path_room_number(code), { number: value }).then(() => {
            console.log('-- number updated', value)
        }).catch((error) => {
            console.error('-- failed to update number', error)
        });
        // sendJsonMessage({
        //     action: 'setNumber',
        //     payload: { number: value }
        // })
        sendAblyMessage(value)
    }


    const sendAblyMessage = async (message) => {
        try {
            // await axios.post(backend_path_notif_room(code), { message })
            ably_room_channel.publish('next-number', { message })
        } catch (error) {
            console.error('-- error sending ably message', error)
        }
    }


    const handlePrint = () => {
        const printContent = document.getElementById('printable-content').innerHTML;
        const iframe = printFrameRef.current;
        iframe.srcdoc = `
            <html>
                <head>
                    <title>Print QR Code</title>
                    <style>
                        body { font-family: Arial, sans-serif; }
                        .container { text-align: center; }
                    </style>
                </head>
                <body>
                    <div class="container">
                        <h1>${classroom.name}</h1>
                        ${printContent}
                    </div>
                </body>
            </html>
        `;
        iframe.onload = () => {
            iframe.contentWindow.print();
        };
    };

    if (!classroom) {
        return <div>Loading...</div>;
    }


    return (
        <>
            <div className="container mt-4">
                <div className="row justify-content-center">
                    <div className="col-md-8 text-center">
                        <h1 className="mb-4">{t('owner-room-welcome')} <br /> {t('room-name-label')}: {classroom.name} - {t('room-code-label')}: {classroom.code}</h1>
                        <div className="card">
                            <div className="card-body">
                                <h3 className="card-title mb-3">Current Number:</h3>
                                <p className="display-1 font-weight-bold mb-4">{currentNumber}</p>
                                <div className="d-flex justify-content-center align-items-center mb-3">
                                    <input
                                        type="number"
                                        className="form-control form-control-lg me-2"
                                        style={{ width: '150px', fontSize: '2rem' }}
                                        value={inputNumber}
                                        onChange={(e) => setInputNumber(parseInt(e.target.value) || 0)}
                                    />
                                    <button
                                        className="btn btn-success btn-lg"
                                        style={{ fontSize: '2rem' }}
                                        onClick={() => updateAndSendNumber(inputNumber)}
                                    >
                                        Set
                                    </button>
                                </div>
                                <button
                                    className="btn btn-primary btn-lg btn-block w-100"
                                    style={{ fontSize: '2rem' }}
                                    onClick={() => updateAndSendNumber(currentNumber + 1)}
                                >
                                    Next
                                </button>
                            </div>
                        </div>
                        <br />
                        <div id="printable-content">
                            <div className="card mb-4">
                                <div className="card-body">
                                    <h3 className="card-title">Scan mã QR này để theo dõi số hiện tại:</h3>
                                    <br />
                                    <div className="d-flex justify-content-center mb-3">
                                        <QRCodeSVG value={qrCodeUrl} size={256} />
                                    </div>
                                    <p className="card-text">
                                        <a href={qrCodeUrl} target="_blank" rel="noopener noreferrer">
                                            {qrCodeUrl}
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <button className="btn btn-secondary mb-4" onClick={handlePrint}>Print QR Code and Link</button>
                    </div>
                </div>
            </div>
            <iframe ref={printFrameRef} style={{ display: 'none' }} title="Print Frame" />
        </>
    );
};

export default OwnerRoom;
