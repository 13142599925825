import React, { useState, useEffect } from 'react';
import axios from './axiosConfig';
import { AuthProvider } from './AuthProvider';
import NavigationMenu from './NavigationMenu';
import { BACKEND_HOST, APP_NAME } from './constants';
import { useNavigate } from 'react-router-dom';


const RoomAdmin = () => {
    const [rooms, setRooms] = useState([]);
    const [currentRoom, setCurrentRoom] = useState({ name: '' });
    const [isEditing, setIsEditing] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        fetchRooms();
    }, []);

    const fetchRooms = async () => {
        try {
            const response = await axios.get(`${BACKEND_HOST}/api/${APP_NAME}/rooms`);
            setRooms(response.data);
        } catch (error) {
            console.error('Error fetching rooms:', error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCurrentRoom({ ...currentRoom, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (isEditing) {
                await axios.put(`${BACKEND_HOST}/api/${APP_NAME}/rooms/${currentRoom.code}`, currentRoom);
            } else {
                await axios.post(`${BACKEND_HOST}/api/${APP_NAME}/rooms`, currentRoom);
            }
            fetchRooms();
            setCurrentRoom({ name: '' });
            setIsEditing(false);
        } catch (error) {
            console.error('Error saving room:', error);
        }
    };

    const handleEdit = (room) => {
        if (window.confirm('Are you sure you want to edit this room?')) {
            setCurrentRoom(room);
            setIsEditing(true);
        }
    };

    const handleDelete = async (code) => {
        if (window.confirm('Are you sure you want to delete this room?')) {
            try {
                await axios.delete(`${BACKEND_HOST}/api/${APP_NAME}/rooms/${code}`);
                fetchRooms();
            } catch (error) {
                console.error('Error deleting room:', error);
            }
        }
    };

    const handleNavigateToRoom = (code) => {
        navigate(`/owner_room/${code}`);
    };

    return (
        <AuthProvider>
            <div className="container mt-4">
                <h2>{isEditing ? 'Edit Room' : 'Create New Room'}</h2>
                <form onSubmit={handleSubmit} className="mb-4">
                    <div className="mb-3">
                        <label htmlFor="name" className="form-label">Room Name</label>
                        <input
                            type="text"
                            className="form-control"
                            id="name"
                            name="name"
                            value={currentRoom.name}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    <button type="submit" className="btn btn-primary">
                        {isEditing ? 'Update Room' : 'Create Room'}
                    </button>
                    {isEditing && (
                        <button type="button" className="btn btn-secondary ms-2" onClick={() => {
                            setCurrentRoom({ name: '' });
                            setIsEditing(false);
                        }}>
                            Cancel
                        </button>
                    )}
                </form>

                <h2 className="mb-4">Existing Rooms</h2>
                <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
                    {rooms.map(room => (
                        <div key={room.code} className="col" id={`room-${room.code}`}>
                            <div className="card h-100">
                                <div className="card-body d-flex flex-column">
                                    <h5 className="card-title text-center" id={`room-name-${room.name}`}>{room.name}</h5>
                                    <p className="card-text text-muted small text-center" id={`room-${room.code}`}>Code: {room.code}</p>
                                    <div className="flex-grow-1 d-flex align-items-center justify-content-center">
                                        <p className="display-1 mb-0">{room.last_number || 0}</p>
                                    </div>
                                    <br /><br />
                                    <div className="mt-auto d-flex justify-content-center">
                                        <button
                                            className="btn btn-sm btn-outline-success me-2"
                                            onClick={() => handleNavigateToRoom(room.code)}
                                            title="Go to Room"
                                        >
                                            <i className="bi bi-box-arrow-in-right"></i> Enter
                                        </button>
                                        <button
                                            className="btn btn-sm btn-outline-primary me-2"
                                            onClick={() => handleEdit(room)}
                                            title="Edit Room"
                                        >
                                            <i className="bi bi-pencil"></i> Edit
                                        </button>
                                        <button
                                            className="btn btn-sm btn-outline-danger"
                                            onClick={() => handleDelete(room.code)}
                                            title="Delete Room"
                                        >
                                            <i className="bi bi-trash"></i> Delete
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </AuthProvider>
    );
};

export default RoomAdmin;
