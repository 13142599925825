import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import { store } from './store/store';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import UserProfilePage from './UserProfilePage';
import ClassRoomPage from './ClassRoomPage';
import JoinClassroom from './ClassroomStagingPage';
import OwnerRoom from './OwnerRoom';
import RoomAdmin from './RoomAdmin';
import CalendarPage from './CalendarPage';
import BusinessList from './components/business/BusinessList';
import BusinessForm from './components/business/BusinessForm';
import BusinessDetail from './components/business/BusinessDetail';
import EmployeeManagement from './components/employees/EmployeeManagement';
import ActivateAccount from './components/auth/ActivateAccount';
import NavigationMenu from './NavigationMenu';
import DemoPage from './DemoPage';
import { AuthProvider } from './AuthProvider';
import 'bootstrap-icons/font/bootstrap-icons.css'
import './i18n'

// Create a Layout component that only includes NavigationMenu
const Layout = ({ children }) => {
  return (
    <>
      <NavigationMenu />
      {children}
    </>
  );
};

const router = createBrowserRouter([
  {
    path: "/", element: <Layout><App /></Layout>
  },
  {
    path: "/demo", element: <Layout><DemoPage /></Layout>
  },
  {
    path: '/rooms',
    element: <Layout><RoomAdmin /></Layout>
  },
  {
    path: '/rooms/:code',
    element: <Layout><ClassRoomPage /></Layout>
  },
  {
    path: '/join_room',
    element: <Layout><JoinClassroom /></Layout>
  },
  {
    path: '/owner_room/:code',
    element: <Layout><OwnerRoom /></Layout>
  },
  {
    path: '/calendar',
    element: <Layout><CalendarPage /></Layout>
  },
  {
    path: '/businesses',
    element: <Layout><BusinessList /></Layout>
  },
  {
    path: '/business/edit/:id',
    element: <Layout><BusinessForm /></Layout>
  },
  {
    path: '/business/:id',
    element: <Layout><BusinessDetail /></Layout>
  },
  {
    path: '/business/create',
    element: <Layout><BusinessForm /></Layout>
  },
  {
    path: '/employees',
    element: <Layout><EmployeeManagement /></Layout>
  },
  {
    path: '/activate',
    element: <Layout><ActivateAccount /></Layout>
  }
]);

// Wrap the entire app with Provider and AuthProvider
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <AuthProvider>
        <div className='container'>
          <RouterProvider router={router} />
        </div>
      </AuthProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
