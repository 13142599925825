import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from '../../axiosConfig';
import { backend_path_business } from '../../constants';

const BusinessForm = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [formData, setFormData] = useState({
        name: '',
        address: '',
        phone_number: '',
        email: '',
        description: ''
    });
    const [error, setError] = useState(null);

    useEffect(() => {
        if (id) {
            fetchBusiness();
        }
    }, [id]);

    const fetchBusiness = async () => {
        try {
            const response = await axios.get(`${backend_path_business}${id}/`);
            setFormData(response.data);
        } catch (err) {
            setError('Failed to fetch business details');
        }
    };

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (id) {
                await axios.put(`${backend_path_business}${id}/`, formData);
            } else {
                await axios.post(backend_path_business, formData);
            }
            navigate('/businesses');
        } catch (err) {
            setError('Failed to save business');
        }
    };

    return (
        <div className="container mt-4">
            <h2>{id ? 'Edit Business' : 'Create New Business'}</h2>
            {error && <div className="alert alert-danger">{error}</div>}
            <form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <label className="form-label">Business Name</label>
                    <input
                        type="text"
                        className="form-control"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="mb-3">
                    <label className="form-label">Address</label>
                    <input
                        type="text"
                        className="form-control"
                        name="address"
                        value={formData.address}
                        onChange={handleChange}
                    />
                </div>
                <div className="mb-3">
                    <label className="form-label">Phone Number</label>
                    <input
                        type="tel"
                        className="form-control"
                        name="phone_number"
                        value={formData.phone_number}
                        onChange={handleChange}
                    />
                </div>
                <div className="mb-3">
                    <label className="form-label">Email</label>
                    <input
                        type="email"
                        className="form-control"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                    />
                </div>
                <div className="mb-3">
                    <label className="form-label">Description</label>
                    <textarea
                        className="form-control"
                        name="description"
                        value={formData.description}
                        onChange={handleChange}
                        rows="3"
                    />
                </div>
                <button type="submit" className="btn btn-primary">
                    {id ? 'Update' : 'Create'} Business
                </button>
            </form>
        </div>
    );
};

export default BusinessForm; 