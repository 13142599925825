import React, { useEffect, useState } from 'react';
import axios from './axiosConfig';
import { redirect, useNavigate } from 'react-router-dom';
import NavigationMenu from './NavigationMenu';
import { AuthProvider } from './AuthProvider';
import { BACKEND_HOST, APP_NAME } from './constants';

const JoinClassroom = () => {
    const [classroomCode, setClassroomCode] = useState('');
    const [classroom, setClassroom] = useState({});
    const navigate = useNavigate()

    useEffect(() => {
        const code = localStorage.getItem('classroom_code')
        if (code) {
            setClassroomCode(code)
        }
    }, [])

    const handleJoinAsTeacher = async () => {
        // Create new classroom if classroom code is empty
        try {
            const response = await axios.post(`${BACKEND_HOST}/api/${APP_NAME}/join_room`, {
                is_owner: true,
                code: classroomCode.trim()
            });
            const userProfileRes = await axios.get(`${BACKEND_HOST}/api/${APP_NAME}/user_profile/`)
            const userProfile = userProfileRes.data;
            console.log('New classroom created:', response.data);
            const classroom = response.data;
            const classroom_code = classroom.code;
            localStorage.setItem('classroom_code', classroom_code)
            setClassroomCode(classroom_code)
            navigate(`/owner_room/${classroom_code}`, { state: { classroom, userProfile } })
            setClassroom(classroom)
            // Handle success, e.g., redirect or show success message
        } catch (error) {
            console.error('Failed to create classroom:', error);
            // Handle error, show error message
        }
    };

    const handleJoinAsStudent = async () => {
        if (classroomCode.trim()) {
            // Join as student in existing classroom
            try {
                setClassroomCode(classroomCode)
                const response = await axios.post(`${BACKEND_HOST}/api/${APP_NAME}/join_room`, {
                    code: classroomCode,
                    teacher: false
                });
                if (response.status == 404) {
                    alert("room not found")
                    return
                }
                const classroom = response.data;
                console.log('Joined classroom as student:', response.data);
                const userProfileRes = await axios.get(`${BACKEND_HOST}/api/${APP_NAME}/user_profile/`)
                const userProfile = userProfileRes.data;
                setClassroom(classroom)
                navigate('/classroom', { state: { classroom, userProfile } })
                // Handle success, e.g., redirect or show success message
            } catch (error) {
                console.error('Failed to join classroom as student:', error);
                // Handle error, show error message
            }
        } else {
            // Handle case where classroom code is empty for student join
            console.error('Classroom code cannot be empty for joining as student.');
            // Show error message or alert to user
        }
    };

    return (
        <>
            <h1>Join Classroom</h1>
            <input
                type="text"
                placeholder="Enter Classroom Code"
                value={classroomCode}
                onChange={(e) => setClassroomCode(e.target.value)}
            />
            <br />
            <button onClick={handleJoinAsTeacher}>Join As Teacher</button>
            <button onClick={handleJoinAsStudent}>Join As Student</button>
        </>
    );
};

export default JoinClassroom;
