import React, { useEffect, useState } from 'react';
import { AuthProvider } from './AuthProvider';
import NavigationMenu from './NavigationMenu';

const CalendarPage = () => {
    const [calendarHeight, setCalendarHeight] = useState('600px');

    useEffect(() => {
        // Adjust calendar height based on window size
        const updateHeight = () => {
            const windowHeight = window.innerHeight;
            const navbarHeight = 60; // Adjust based on your navbar height
            const padding = 40; // Some padding from top and bottom
            setCalendarHeight(`${windowHeight - navbarHeight - padding}px`);
        };

        // Initial height set
        updateHeight();

        // Update height on window resize
        window.addEventListener('resize', updateHeight);

        // Cleanup
        return () => window.removeEventListener('resize', updateHeight);
    }, []);

    return (
        <div className="container-fluid mt-3">
            <div className="row">
                <div className="col-12">
                    <div className="calendar-container" style={{ height: calendarHeight }}>
                        <iframe
                            src="https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=Asia%2FTokyo&showTitle=0&showNav=1&showPrint=0&showTabs=1&showCalendars=0"
                            style={{
                                border: 0,
                                width: '100%',
                                height: '100%',
                                frameBorder: 0,
                                scrolling: 'no'
                            }}
                            title="Google Calendar"
                        ></iframe>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CalendarPage; 