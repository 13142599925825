// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enTranslation from './locales/en/translation.json';
import vnTranslation from './locales/vi/translation.json';

i18n.use(initReactI18next).init({
    resources: {
        en: { translation: enTranslation },
        vi: { translation: vnTranslation },
    },
    lng: 'vi', // Default language
    fallbackLng: 'vi', // Fallback language if translation is missing
    interpolation: {
        escapeValue: false, // React already sanitizes text
    },
});

export default i18n;