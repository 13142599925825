import React, { useState, useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Ably from 'ably';
import { QRCodeSVG } from 'qrcode.react';
import { ably_path_auth } from './constants';

const DemoPage = () => {
    const [currentNumber, setCurrentNumber] = useState(0);
    const [notificationDenied, setNotificationDenied] = useState(false);
    const [channelId] = useState(() => uuidv4());
    const [swRegistration, setSwRegistration] = useState(null);
    const [retryNotification, setRetryNotification] = useState(false);
    const [currentUrl, setCurrentUrl] = useState('');
    const lastNotificationRef = useRef(null);
    const NOTIFICATION_COOLDOWN = 2000; // 2 seconds cooldown
    const INTERVAL = 10; // 15 seconds for the main timer
    const [countdown, setCountdown] = useState(INTERVAL);
    
    // Create a ref to hold the registration
    const swRegistrationRef = useRef(null);

    useEffect(() => {
        // Initialize number from localStorage or generate new one
        const storedNumber = localStorage.getItem('demoCurrentNumber');
        if (storedNumber) {
            setCurrentNumber(parseInt(storedNumber));
        } else {
            const randomNumber = Math.floor(Math.random() * 100) || 0;
            setCurrentNumber(randomNumber);
            localStorage.setItem('demoCurrentNumber', randomNumber.toString());
        }

        // on Zalo, currentNumber becomes NAN
        if (currentNumber===null || isNaN(currentNumber)) {
            setCurrentNumber(0);
        }

        // Register service worker
        registerServiceWorker();
        requestNotificationPermission();

        // Set up number increment interval
        const incrementId = setInterval(() => {
            incrementNumber();
            setCountdown(INTERVAL); // Reset countdown after increment
        }, INTERVAL * 1000);

        // Set up countdown interval
        const countdownId = setInterval(() => {
            setCountdown(prev => prev > 0 ? prev - 1 : 0);
        }, 1000);

        setCurrentUrl(window.location.href);

        return () => {
            clearInterval(incrementId);
            clearInterval(countdownId);
        };
    }, []);

    const registerServiceWorker = async () => {
        try {
            if ('serviceWorker' in navigator) {
                const registration = await navigator.serviceWorker.register('/service-worker.js');
                if (registration) {
                    console.log('-- ServiceWorker registration successful', registration);
                    setSwRegistration(registration);
                    swRegistrationRef.current = registration; // Store in ref
                    console.log('-- Registration stored in ref', swRegistrationRef.current);
                } else {
                    console.log('-- registration is null');
                }
            } else {
                console.error('ServiceWorker not supported');
            }
        } catch (error) {
            console.error('ServiceWorker registration failed:', error);
        }
    };

    const requestNotificationPermission = async () => {
        if (!("Notification" in window)) {
            alert("This browser does not support desktop notification. Please try Chrome");
            return;
        }

        try {
            const permission = await Notification.requestPermission();
            console.log("Notification permission:", permission);

            if (permission === "granted") {
                sendTestNotification();
            } else if (permission === "denied") {
                setNotificationDenied(true);
                if (!retryNotification) {
                    setRetryNotification(true);
                    setTimeout(() => {
                        requestNotificationPermission();
                    }, 2000);
                }
            }
        } catch (error) {
            console.error("Error requesting notification permission:", error);
        }
    };

    const sendTestNotification = async () => {
        try {
            // Use the ref instead of state
            const currentRegistration = swRegistrationRef.current;
            console.log('-- Attempting to show test notification with registration:', currentRegistration);

            if (currentRegistration && Notification.permission === "granted") {
                await currentRegistration.showNotification("Test Notification", {
                    body: "Notifications are working!",
                    icon: '/logo192.png',
                    badge: '/logo192.png',
                    vibrate: [200, 100, 200]
                });
            } else {
                console.log('-- No service worker registration available for test notification');
            }
        } catch (error) {
            console.error("Error showing test notification:", error);
        }
    };

    const showNotification = async (message) => {
        if (Notification.permission !== "granted") {
            return;
        }

        // Check if enough time has passed since last notification
        const now = Date.now();
        if (lastNotificationRef.current && (now - lastNotificationRef.current) < NOTIFICATION_COOLDOWN) {
            console.log('Notification skipped - too soon after last notification');
            return;
        }

        try {
            const currentRegistration = swRegistrationRef.current;
            console.log('-- Attempting to show notification with registration:', currentRegistration);

            if (currentRegistration) {
                await currentRegistration.showNotification("Number Updated", {
                    body: `Current number is now: ${message}`,
                    icon: '/logo192.png',
                    badge: '/logo192.png',
                    vibrate: [200, 100, 200],
                    data: {
                        channelId: channelId,
                        currentNumber: message,
                        timestamp: new Date().toISOString()
                    }
                });
                lastNotificationRef.current = now;
            } else {
                console.log('-- No service worker registration available');
                // Fallback to regular notification
                new Notification("Number Updated", {
                    body: `Current number is now: ${message}`,
                    icon: '/logo192.png'
                });
                lastNotificationRef.current = now;
            }
        } catch (error) {
            console.error("Error showing notification:", error);
        }
    };

    const incrementNumber = () => {
        setCurrentNumber(prev => {
            const newNumber = prev + 1;
            localStorage.setItem('demoCurrentNumber', newNumber.toString());
            // if notifications are not supported, don't show them
            if (!("Notification" in window)) {
                console.log("-- This browser does not support desktop notification");
                return newNumber;
            }
            showNotification(newNumber);
            return newNumber;
        });
    };

    return (
        <div className="container mt-5">
            <div className="row justify-content-center">
                <div className="col-md-8 text-center">
                    <h1 className="mb-4">Demo Room</h1>
                    <div className="card mb-4">
                        <div className="card-body">
                            <h2 className="card-title">Current Number:</h2>
                            <p className="display-1 font-weight-bold mb-4">
                                {currentNumber}
                            </p>
                            <div className="d-flex flex-column align-items-center">
                                <p className="text-muted mb-2">
                                    Next update in:
                                </p>
                                <p className="display-4 mb-3 text-primary">
                                    {countdown}s
                                </p>
                                <div className="progress w-50 mb-3">
                                    <div 
                                        className="progress-bar" 
                                        role="progressbar" 
                                        style={{ 
                                            width: `${(countdown / INTERVAL) * 100}%`,
                                            transition: 'width 1s linear'
                                        }}
                                        aria-valuenow={countdown}
                                        aria-valuemin="0"
                                        aria-valuemax={INTERVAL}
                                    />
                                </div>
                            </div>
                            {notificationDenied && (
                                <div className="alert alert-warning">
                                    Please enable notifications for the best experience
                                    <button 
                                        className="btn btn-sm btn-warning ms-2"
                                        onClick={requestNotificationPermission}
                                    >
                                        Enable Notifications
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                    
                    <div className="card">
                        <div className="card-body">
                            <h3 className="card-title mb-4">Share this page</h3>
                            <div className="d-flex justify-content-center">
                                <QRCodeSVG 
                                    value={currentUrl}
                                    size={200}
                                    level="H"
                                    includeMargin={true}
                                    className="mb-3"
                                />
                            </div>
                            <p className="text-muted small">
                                Scan to open this page on another device
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DemoPage; 