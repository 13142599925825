import { createSlice } from '@reduxjs/toolkit';

// Load initial state from localStorage
const loadInitialState = () => {
  try {
    const token = localStorage.getItem('accessToken');
    const userProfile = JSON.parse(localStorage.getItem('userProfile'));
    
    return {
      profile: userProfile,
      isAuthenticated: !!token,
      token: token,
    };
  } catch (error) {
    console.error('Error loading state from localStorage:', error);
    return {
      profile: null,
      isAuthenticated: false,
      token: null,
    };
  }
};

const userSlice = createSlice({
  name: 'user',
  initialState: loadInitialState(),
  reducers: {
    setUser: (state, action) => {
      state.profile = action.payload;
      state.isAuthenticated = true;
      // Persist to localStorage
      localStorage.setItem('userProfile', JSON.stringify(action.payload));
    },
    setToken: (state, action) => {
      state.token = action.payload;
      state.isAuthenticated = true;
      // Persist to localStorage
      localStorage.setItem('accessToken', action.payload);
    },
    clearUser: (state) => {
      state.profile = null;
      state.isAuthenticated = false;
      state.token = null;
      // Clear localStorage
      localStorage.removeItem('userProfile');
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
    },
  },
});

export const { setUser, setToken, clearUser } = userSlice.actions;

export const selectUser = (state) => state.user.profile;
export const selectIsAuthenticated = (state) => state.user.isAuthenticated;
export const selectToken = (state) => state.user.token;

export default userSlice.reducer; 