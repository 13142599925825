import { QRCodeSVG } from 'qrcode.react';
import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import useWebSocket from 'react-use-websocket';
import AnswerPanel from './AnswerPanel';
import { AuthProvider } from './AuthProvider';
import './ClassRoomPage.css';
import {
    ws_classroom_channel,
    backend_path_room,
    ably_path_room_channel,
    ably_path_auth
} from './constants';
import NavigationMenu from './NavigationMenu';
import { useParams } from 'react-router-dom';
import axios from './axiosConfig';
import Ably from "ably"; // Using Ably with promises

const ClassRoomPage = () => {
    const [currentNumber, setCurrentNumber] = useState(0)
    const [inputNumber, setInputNumber] = useState(0)
    const [userNumber, setUserNumber] = useState('')
    const [room, setRoom] = useState({ name: 'Loading' })
    const { code } = useParams()
    const { state } = useLocation();
    console.log('-- classroom', room)
    const name = ""
    const [messages, setMessages] = useState([]);
    const [message, setMessage] = useState('')
    const ably = new Ably.Realtime({ authUrl: ably_path_auth });
    const ably_room_channel = ably.channels.get(ably_path_room_channel(code));
    const [swRegistration, setSwRegistration] = useState(null);
    const [pushSubscription, setPushSubscription] = useState(null);

    // Create a ref to hold the registration
    const swRegistrationRef = useRef(null);

    useEffect(() => {
        registerServiceWorker();
        requestNotificationPermission();
    }, []);

    const registerServiceWorker = async () => {
        try {
            if ('serviceWorker' in navigator) {
                const registration = await navigator.serviceWorker.register('/service-worker.js');
                if (registration) {
                    console.log('-- ServiceWorker registration successful', registration);
                    setSwRegistration(registration);
                    swRegistrationRef.current = registration; // Store in ref
                    console.log('-- Registration stored in ref', swRegistrationRef.current);
                } else {
                    console.log('-- registration is null')
                }
            } else {
                console.error('ServiceWorker not supported');
            }
        } catch (error) {
            console.log('ServiceWorker registration failed:', error);
        }
    };

    const requestNotificationPermission = async () => {
        if (!("Notification" in window)) {
            alert("This browser does not support desktop notification");
            return;
        }

        try {
            const permission = await Notification.requestPermission();
            console.log("Notification permission:", permission);

            if (permission === "granted") {
                // Subscribe to push notifications here if needed
                await subscribeToPushNotifications();
            }
        } catch (error) {
            console.error("Error requesting notification permission:", error);
        }
    };

    const subscribeToPushNotifications = async () => {
        try {
            if (!swRegistrationRef.current) {
                console.log('No service worker registration');
                return;
            }

            // Check if we already have a subscription
            let subscription = await swRegistrationRef.current.pushManager.getSubscription();

            if (!subscription) {
                // Get the server's public key
                // const response = await fetch('/api/push-public-key');
                // const publicKey = await response.text();

                // Create a new subscription
                subscription = await swRegistrationRef.current.pushManager.subscribe({
                    userVisibleOnly: true,
                    // applicationServerKey: publicKey
                });

                // Send the subscription to your server
                // await fetch('/api/push-subscription', {
                //     method: 'POST',
                //     headers: {
                //         'Content-Type': 'application/json',
                //     },
                //     body: JSON.stringify(subscription)
                // });
            }

            setPushSubscription(subscription);
            console.log('Push notification subscription successful:', subscription);
        } catch (error) {
            console.error('Error subscribing to push notifications:', error);
            if (error.name === 'NotAllowedError') {
                console.log('Permission for push notifications was denied');
            } else if (error.name === 'NotSupportedError') {
                console.log('Push notifications are not supported');
            }
        }
    };

    const showNotification = async (message) => {
        if (Notification.permission !== "granted") {
            return;
        }

        try {
            // Use the ref instead of state
            const currentRegistration = swRegistrationRef.current;
            console.log('-- Attempting to show notification with registration:', currentRegistration);

            if (currentRegistration) {
                const notificationTitle = userNumber && parseInt(message) >= userNumber
                    ? "Your number is called!"
                    : "Number updated";

                const notificationBody = userNumber && parseInt(message) >= userNumber
                    ? `Your number (${userNumber}) has been called!`
                    : `Current number: ${message}`;

                await currentRegistration.showNotification(notificationTitle, {
                    body: notificationBody,
                    icon: '/logo192.png',
                    badge: '/logo192.png',
                    vibrate: [200, 100, 200],
                    data: {
                        roomCode: code,
                        roomName: room.name,
                        currentNumber: message,
                        userNumber: userNumber
                    },
                    actions: userNumber && parseInt(message) >= userNumber ? [
                        { action: 'acknowledge', title: 'OK' }
                    ] : undefined
                });
            } else {
                console.log('-- current swRegistration', swRegistration)
                // Fallback to regular notification if service worker isn't ready
                new Notification("Number updated", {
                    body: `Current number: ${message}`,
                    icon: '/logo192.png'
                });
            }
        } catch (error) {
            console.error("Error showing notification:", error);
        }
    };

    useEffect(() => {
        axios.get(backend_path_room(code)).then((response) => {
            console.log('-- room data', response.data)
            setRoom(response.data)
            setCurrentNumber(response.data.last_number)
        }).catch((error) => {
            console.log('-- error requesting room ' + code, error)
        })
    }, [])
    useEffect(async () => {
        await ably_room_channel.subscribe('next-number', (message) => {
            console.log('--ably received ', message)
            const receivedNumber = message.data.message
            setCurrentNumber(receivedNumber)
            // Wrap showNotification in a setTimeout to ensure registration is available
            setTimeout(() => {
                showNotification(receivedNumber);
                if (userNumber !== null && userNumber > 0 && receivedNumber >= userNumber) {
                    console.log('-- your number is called, received', receivedNumber, 'Your number', userNumber);
                    showNotification('Your number is called');
                }
            }, 0);
        })
    }, [])

    const onInputChange = (e) => {
        setMessage(e.target.value)
    }

    const handleSetNumber = (value) => {
        const numberValue = parseInt(value) || 0;
        setUserNumber(numberValue)
    };

    return (
        // <AuthProvider>
        //     <div><NavigationMenu /></div>
        //     <h1>You are following room: <br />{room.name} - {code}</h1>
        //     <h2>Current Number: {currentNumber}</h2>
        // </AuthProvider>
        <div className="container mt-5">
            <div className="row justify-content-center">
                <div className="col-md-8 text-center">
                    <h1 className="mb-4">You are following room: <br />{room.name} - {code}</h1>
                    <div className="card">
                        <div className="card-body">
                            <h2 className="card-title">Current Number:</h2>
                            <p className="display-1 font-weight-bold mb-4">{currentNumber}</p>
                            <p className="display-6 font-weight-bold mb-4">Your Number</p>
                            <p>You will be notified when your number is called</p>
                            <div className="d-flex justify-content-center align-items-center">
                                <input
                                    className="form-control form-control-lg me-2"
                                    style={{
                                        width: '150px',
                                        fontSize: '2rem',
                                        height: '60px'
                                    }}
                                    value={inputNumber}
                                    onChange={(e) => setInputNumber(parseInt(e.target.value) || 0)}
                                />
                                <button
                                    className="btn btn-success btn-lg"
                                    style={{
                                        fontSize: '2rem',
                                        height: '60px'
                                    }}
                                    onClick={(e) => setUserNumber(inputNumber)}
                                >
                                    Set
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ClassRoomPage;
