import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from '../../axiosConfig';

const ActivateAccount = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const [status, setStatus] = useState('pending'); // pending, success, error
    const [message, setMessage] = useState('');
    const [credentials, setCredentials] = useState(null);

    useEffect(() => {
        const token = searchParams.get('token');
        if (token) {
            activateAccount(token);
        } else {
            setStatus('error');
            setMessage('Invalid activation link');
        }
    }, [searchParams]);

    const activateAccount = async (token) => {
        try {
            const response = await axios.get(`/api/employees/activate/?token=${token}`);
            setStatus('success');
            setCredentials(response.data);
            setMessage('Account activated successfully!');
        } catch (error) {
            setStatus('error');
            setMessage(error.response?.data?.error || 'Activation failed');
        }
    };

    return (
        <div className="container mt-5">
            <div className="row justify-content-center">
                <div className="col-md-6">
                    <div className="card">
                        <div className="card-body text-center">
                            <h3 className="card-title mb-4">Account Activation</h3>
                            
                            {status === 'pending' && (
                                <div className="spinner-border text-primary" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            )}

                            {status === 'error' && (
                                <div className="alert alert-danger" role="alert">
                                    {message}
                                </div>
                            )}

                            {status === 'success' && credentials && (
                                <div>
                                    <div className="alert alert-success" role="alert">
                                        {message}
                                    </div>
                                    <div className="mt-4">
                                        <h5>Your Login Credentials</h5>
                                        <p><strong>Email:</strong> {credentials.email}</p>
                                        <p><strong>Temporary Password:</strong> {credentials.temp_password}</p>
                                        <div className="alert alert-warning">
                                            Please save these credentials and change your password after logging in.
                                        </div>
                                        <h5 className="mt-3">Activated Businesses:</h5>
                                        <ul className="list-unstyled">
                                            {credentials.businesses.map(business => (
                                                <li key={business.id}>{business.name}</li>
                                            ))}
                                        </ul>
                                    </div>
                                    <button 
                                        className="btn btn-primary mt-3"
                                        onClick={() => navigate('/login')}
                                    >
                                        Go to Login
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ActivateAccount; 